var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      ref: "container",
      staticClass: "hand-movement",
      class: { "hand-movement--in-progress": !_vm.hasWinner },
      attrs: { shrink: "" },
    },
    [
      !_vm.hasWinner && _vm.isHost
        ? _c(
            "div",
            {},
            [
              _c(
                "RtbButton",
                {
                  attrs: {
                    outline: !_vm.gameStatus.handMovementStarted,
                    color: _vm.gameStatus.handMovementStarted
                      ? "danger"
                      : "primary",
                  },
                  on: { click: _vm.onStartHandMovement },
                },
                [
                  _c("span", { staticStyle: { "font-weight": "bolder" } }, [
                    _vm._v(
                      _vm._s(
                        !_vm.gameStatus.handMovementStarted ? "Start" : "Pause"
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.hasWinner && _vm.scribeTeamID
        ? _c(
            "v-layout",
            { attrs: { "align-center": "" } },
            [
              _c("v-icon", { attrs: { dark: "", "mr-2": "" } }, [
                _vm._v(_vm._s(_vm.viewerTeam.icon)),
              ]),
              _c(
                "v-progress-linear",
                { attrs: { value: _vm.localProgress, height: "32" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { "fill-height": "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { grow: "" } }, [
                        _c("strong", [
                          _vm._v(" " + _vm._s(_vm.viewerTeam.name) + " "),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.otherTeamsByPositions, function (team, index) {
        return _c(
          "v-layout",
          { key: index, attrs: { "align-center": "" } },
          [
            _c("v-icon", { staticClass: "mr-2", attrs: { dark: "" } }, [
              _vm._v(_vm._s(team.icon)),
            ]),
            _c(
              "v-progress-linear",
              {
                attrs: {
                  value: team.handmovement.value,
                  height: "32",
                  color:
                    (_vm.hasWinner && team.position === 0 && "green") ||
                    undefined,
                },
              },
              [
                _c(
                  "v-layout",
                  { attrs: { "fill-height": "", "align-center": "" } },
                  [
                    _c("v-flex", { attrs: { grow: "" } }, [
                      _c("strong", [_vm._v(_vm._s(team.name) + " ")]),
                    ]),
                    _vm.hasWinner
                      ? _c(
                          "v-flex",
                          {
                            staticClass: "mr-4",
                            attrs: { shrink: "", xs3: "" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.getLabel(team.position)) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }