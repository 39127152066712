<template>
  <v-flex
    shrink
    :class="{ 'hand-movement--in-progress': !hasWinner }"
    class="hand-movement"
    ref="container"
  >
    <div class="" v-if="!hasWinner && isHost">
      <RtbButton
        :outline="!gameStatus.handMovementStarted"
        @click="onStartHandMovement"
        :color="gameStatus.handMovementStarted ? 'danger' : 'primary'"
      >
        <span style="font-weight: bolder">{{
          !gameStatus.handMovementStarted ? "Start" : "Pause"
        }}</span>
      </RtbButton>
    </div>

    <v-layout align-center v-if="!hasWinner && scribeTeamID">
      <v-icon dark mr-2>{{ viewerTeam.icon }}</v-icon>
      <v-progress-linear :value="localProgress" height="32">
        <v-layout fill-height align-center>
          <v-flex grow>
            <strong>
              {{ viewerTeam.name }}
            </strong>
          </v-flex>
        </v-layout>
      </v-progress-linear>
    </v-layout>

    <v-layout
      align-center
      v-for="(team, index) in otherTeamsByPositions"
      :key="index"
    >
      <v-icon dark class="mr-2">{{ team.icon }}</v-icon>
      <v-progress-linear
        :value="team.handmovement.value"
        height="32"
        :color="(hasWinner && team.position === 0 && 'green') || undefined"
      >
        <v-layout fill-height align-center>
          <v-flex grow>
            <strong>{{ team.name }} </strong>
          </v-flex>
          <v-flex v-if="hasWinner" shrink class="mr-4" xs3>
            {{ getLabel(team.position) }}
          </v-flex>
        </v-layout>
      </v-progress-linear>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { GameMixin } from "@/mixins"

import { RtbButton } from "@/components/ui"

import { db, ServerValue } from "@/firebase"
import { orderBy, sortBy } from "lodash"
import Team from "@shared/Team"

import Event from "./enums/Event"

import * as moment from "moment"

const UPADTE_WAIT_TIME = 1.5 * 1000 // 1.5 sec.

export default {
  name: "PlayArea",
  mixins: [GameMixin],
  components: {
    RtbButton
  },
  props: {
    mission: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tempProgress: 0
    }
  },
  computed: {
    ...mapGetters([
      "user",
      "gameID",
      "orgID",
      "game",
      "gameStatus",
      "isScribe",
      "onlineUsersArray",
      "chats"
    ]),
    ...mapGetters("auth", [
      "isHost",
      "isSuper",
      "token",
      "isAudit",
      "isSpectator",
      "clientID"
    ]),
    ...mapGetters({ currentTeamID: "teamID" }),
    teams() {
      return Team.normalize(this.chats ?? {}).map(team => ({
        ...team,
        handmovement: {
          ...(team.handmovement || {}),
          value: team?.handmovement?.value || 0
        }
      }))
    },
    otherTeams() {
      return this.teams.filter(({ id }) => id !== this.teamID)
    },
    viewerTeam() {
      return this.teams.find(team => team.id === this.teamID)
    },
    hasWinner() {
      return this.teams.some(team => team?.handmovement?.completed)
    },
    teamsByPositions() {
      const sortedTeams = this.teams.sort(
        (teamA, teamB) =>
          (teamB.id === this.teamID) - (teamA.id === this.teamID)
      )

      if (!this.hasWinner) {
        return sortedTeams
      }

      return sortBy(sortedTeams, [
        team => this.mapTeamsByPositions?.[team?.id]
      ]).map((team, index) => {
        return {
          ...team,
          position: index
        }
      })
    },
    otherTeamsByPositions() {
      if (this.hasWinner) {
        return this.teamsByPositions
      }
      return this.teamsByPositions.filter(team => team.id !== this.scribeTeamID)
    },
    mapTeamsByPositions() {
      return orderBy(
        this.teams,
        ["handmovement.value", "handmovement.timestamp"],
        ["desc", "desc"]
      ).reduce((acc, team, index) => {
        acc[team.id] = index
        return acc
      }, {})
    },
    numberOfCompletedTeams() {
      const completed = this.teams.filter(
        team => team?.handmovement?.completed
      ).length

      if (completed === 0) {
        return 1
      }

      return completed
    },
    scribeTeamID() {
      return this.user?.selected && this.user?.teamID
    },
    teamID() {
      return this.$store.getters["group/canViewerSelectGlobalTeam"]
        ? this.$store.getters["group/globalTeamID"]
        : this.currentTeamID
    },
    viewerPlay() {
      return this.plays.find(
        ({ missionID, teamID }) =>
          missionID === this.missionID && teamID === this.teamID
      )
    },
    teamScore() {
      const teamScore = parseInt(this.viewerTeam?.handmovement?.value) || 0
      return teamScore >= 100 ? 100 : teamScore
    },
    localProgress() {
      const result = this.teamScore + this.tempProgress
      return result >= 100 ? 100 : result
    }
  },

  created() {
    this.$bus.$on(Event.onNewScore, async val => {
      if (!val) {
        return
      }

      if (!this._startPredictingTime) {
        this._startPredictingTime = Date.now()
      }

      this.tempProgress += val

      if (this.tempProgress < 0) {
        return
      }

      const timeoutTime =
        Date.now() - this._startPredictingTime >= UPADTE_WAIT_TIME
          ? 0
          : UPADTE_WAIT_TIME

      clearTimeout(this._timeoutID)

      // console.log("tempProgress", this.tempProgress)

      this._timeoutID = setTimeout(async () => {
        this._startPredictingTime = null

        const teamProgress =
          this.viewerTeam?.handmovement?.value + this.tempProgress
        // console.log("teamProgress", teamProgress)
        this.tempProgress = 0

        await db
          .auxiliary()
          .ref()
          .update({
            [`org/${this.orgID}/game/${this.gameID}/teams/${this.teamID}/handmovement/value`]:
              teamProgress,
            [`org/${this.orgID}/game/${this.gameID}/teams/${this.teamID}/handmovement/timestamp`]:
              Date.now(),
            [`org/${this.orgID}/game/${this.gameID}/teams/${this.teamID}/handmovement/completed`]:
              teamProgress >= 100
          })

        this.onSubmit(teamProgress)
      }, timeoutTime)
    })

    this.$bus.$on(Event.onSubmit, async () => {
      if (!this.viewerTeam.handmovement?.completed) {
        await this.updateTeamResults()
      }
    })
  },
  mounted() {
    const container = this.$refs?.container
    if (container && this.mission?.photo) {
      container.style.setProperty(
        "--play-area-running-image",
        `url(${this.mission?.photo})`
      )
    }
  },
  beforeDestroy() {
    this.$bus.$off(Event.onNewScore)
    this.$bus.$off(Event.onSubmit)
  },
  methods: {
    async onSubmit(progress) {
      if (!this.isScribe) return console.log("No rights for submit")

      if (progress < 0) {
        // return on negative points
        return
      }

      if (this.missionCompleted) {
        if (this.viewerPlay && this.teamScore >= 100) {
          await this.updateTeamResults()
        }
      } else {
        this.gotItRight = true
        this.checkAnswer()
      }
    },
    async updateTeamResults() {
      const score =
        parseInt(
          ((this.teamScore / 100) * parseInt(this.mission?.points) || 0) /
            this.numberOfCompletedTeams
        ) || 0

      const update = {}

      if (!this?.viewerPlay?.id) return

      update[
        `org/${this.orgID}/game/${this.gameID}/teams/${this.teamID}/handmovement/value`
      ] = this.teamScore

      update[
        `org/${this.orgID}/game/${this.gameID}/teams/${this.teamID}/handmovement/timestamp`
      ] = Date.now()

      update[
        `org/${this.orgID}/game/${this.gameID}/play/${this.viewerPlay.id}/score`
      ] = score

      update[
        `org/${this.orgID}/game/${this.gameID}/teams/${this.teamID}/totalScore`
      ] = ServerValue.increment(score)

      const teamUsers = this.onlineUsersArray.filter(
        user => user.teamID === this.teamID
      )

      for (const user of teamUsers) {
        update[`client/${this.clientID}/userscore/${user.id}`] =
          ServerValue.increment(score)
      }

      await db.auxiliary().ref().update(update)
    },
    async onStartHandMovement() {
      const value = !Boolean(this.gameStatus?.handMovementStarted) || null

      await this.$store.dispatch("updateGameStatusAny", {
        handMovementStarted: value
      })
    },
    getLabel(index) {
      return index === 0 ? "Winner!" : moment.localeData().ordinal(index + 1)
    }
  }
}
</script>

<style lang="scss">
.hand-movement {
  overflow-y: auto;
  overflow-x: hidden;
  --play-area-running-image: url("https://storage.googleapis.com/rtb-dev2000.appspot.com/gamephotos/99026adb-d582-4601-a3bb-ddd655f7e6b3.png");

  &--in-progress {
    .v-progress-linear__bar__determinate {
      position: relative;
      &::after {
        content: "";
        height: 100%;
        width: 80px;
        background-image: var(--play-area-running-image);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        display: block;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
      }
    }
  }

  &__team {
    display: flex;
  }
}
</style>
